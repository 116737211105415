// .footer {
//   position: relative;
//   top: 160px;
//   width: 100%;
//   height: 610px;
//   background-color: #f0f3f4;
// }

// .bannerContent {
//   position: absolute;
//   top: 50%;
//   left: 10%;
//   -webkit-transform: translate(-10%, -50%);
//   transform: translate(-10%, -50%);
//   width: 100%;
//   z-index: 2;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;
//   text-align: center;
//   padding-right: 20px;
// }
.section1CheckOut {
  background-image: url("../images/detailsBackResp.png");
  // width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;

  h1 {
    font-size: 35px !important;
    font-weight: 700;
    line-height: 51.46px;
    position: relative;
    left: 6px;
    top: 35px;
  }

  .titleName {
    font-size: 15px;
    /* padding-left: 30px; */
    line-height: 23px;
    /* margin-left: -3px; */
    position: relative;
    left: -82px;
    top: -20px 
  }
}
.section1CheckOutResp {
  background-image: url("../images/detailsBackResp.png");
  // width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90px;

  h1 {
    font-size: 24.05px !important;
    font-weight: 700;
    line-height: 35.37px;
    position: relative;
    left: -135px;
    top: 15px 
  }

  .titleName {
    font-size: 15px;
    /* padding-left: 30px; */
    line-height: 23px;
    /* margin-left: -3px; */
    position: relative;
    left: -82px;
    top: -20px 
  }
}
.section1 {
  h1 {
    font-size: 35px !important;
    font-weight: 700;
    line-height: 51.46px;
    position: relative;
    top: 25px;
  }
}

.sub-total {
  position: relative;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-left: 0px;
}

.btn,
// .btn:hover {
//   transition: 0.3s ease-in-out;
// }

// .btn-solid:hover {
//   background-position: 100%;
//   color: #000 !important;
//   background-color: #fff;
// }

.btn {
  // line-height: 20px;
  // text-transform: uppercase;
  // font-size: 14px;
  // font-weight: 700;
  // border-radius: 0;
  line-height: 20px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    line-height: 26.46px;
    border-radius: 0;
    // width: 601px;
    position: relative;
    // left: 35px;
    background-color: #052A75;
    border-radius: 4px;
}

.fonts2 {
  font-size: 25px !important;
  font-family: "Raleway", sans-serif;
  font-weight: bolder;
  padding: 0px;
  padding-left: 100px;
  color: #fff;
  padding-top: 15px;
  margin-left: 50px;
  margin-bottom: 0px;
}

.btn-solid {
  padding: 13px 29px;
  color: #fff !important;
  letter-spacing: 0.05em;
  border: 2px solid #3a519d;
  // background-image: linear-gradient(30deg, #3a519d 50%, transparent 0);
  background-size: 540px;
  background-repeat: no-repeat;
  background-position: 0;
  // transition: background .3s ease-in-out;
}

.checkout_class {
  text-transform: capitalize;
}

.checkout-page {
  .checkout-titleweb {
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    width: 675px;
    /* height: 780px; */
    background-color: #FFFFFF;
    border-radius: 2px;
    position: relative;
    top: 10px;

    .billingblueback{
      background-color: #052A75;
      width: 675px;
      height: 37px;
      position: relative;
      left: 15px;
      .clickhere{
        font-size: 16px;
        font-weight: 500;
        line-height: 23.52px;
        color: #FFFFFF;
        position: relative;
        left: 15px;
        top: 6px;
        cursor: pointer;
      }
    }
    .bilship {
      color: #444;
      font-weight: 700;
      font-size: 26px;
      line-height: 38.23px;
      position: relative;
      left: 30px;
    }

    h3 {
      color: #444;
      font-weight: 700;
    }

    h5 {
      font-size: 22px;
      font-weight: 500;
      line-height: 32.34px;
      position: relative;
      left: 30px;
      top: 25px;
    }

    .booksSection {
      width: 613px;
      height: 196px;
      border: 1px solid rgba(0, 0, 0, 0.1019607843);
      border-radius: 4px;
      position: relative;
      left: 32px;
      top: 20px;
      margin-top: 25px;
    }

    .checkoutImg {
      position: relative;
      top: 50px;
      left: -30px;
      width: 166px;
      height: 93px;
    }

    .courseesname {
      width: 252px;
      height: 52px;
      line-height: 26.46px;
      font-size: 18px;
      font-weight: 500;
      position: relative;
      top: 5px;
    }
    .facultiesname{
      line-height: 20.58px;
      font-size: 14px;
      font-weight: 400;
      position: relative;
      color: #008BC1;
    }
    .ulli {
      position: relative;
      left: -4px;
      top: -2px;
    }
    .ulnames {
      position: relative;
      left: -23px;
      font-size: 10px;
      font-weight: 400;
      line-height: 14.7px;
      color: #6D6D6D;
    }
    .orgArea{
      position: relative;
      top: 7px;
    }
    .original {
      font-size: 14px;
      font-weight: 400;
      line-height: 20.58px;
      color: #616161;
    }
    .price {
      font-size: 14px;
      font-weight: 500;
      line-height: 20.58px;
      color: #000000;
    }
    .addbutton {
      position: relative;
      left: 117px;
      top: 40px;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 5px;
      border: none;
      background-color: #289028;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 500;
      line-height: 20.58px;
    }
    .removebutton {
      position: relative;
      left: 96px;
      top: 40px;
      padding-top: 2px;
      padding-bottom: 5px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 5px;
      border: none;
      background-color: #EA0000;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 500;
      line-height: 20.58px;
    }
    .disArea {
      position: relative;
      top: 132px;
      left: 20px;
    }
    .disPrice {
      font-size: 20px;
      font-weight: 500;
      line-height: 29.4px;
      color: #E92323;
    }
  }
  .checkout-titleweb_account_details {
    padding: 15px 45px 15px 45px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    width: 675px;
    height: 524px;
    background-color: #FFFFFF;
    border-radius: 2px;
    position: relative;
    top: 10px;
    h3{
      font-size: 26px;
      font-weight: 700;
      line-height: 38.23px;
    }
    .userdeta{
      position: relative;
      left: 0px;
      top: -10px;
    }
    .nameareadetail{
      position: relative;
      left: -15px;
    }

    h5{
      font-size: 14px;
      font-weight: 400;
      line-height: 20.58px;
      margin-top: 20px;
      color: #808080;
    }
    h4{
      font-size: 16px;
      font-weight: 500;
      // width: 282px;
    }
    .townarea{
      position: relative;
      left: -15px;
    }
    .townarea2{
      position: relative;
      left: 3px;
    }
    .editbtnaddress{
      width: 289.04px;
      height: 58px;
      border-radius: 4px;
      background-color: #052A75;
      color: #FFFFFF;
      position: relative;
      left: 0px;
      top: 40px;
      cursor: pointer;
    }
    .adredt{
      font-size: 14px;
      font-weight: 600;
      line-height: 20.58px;
      position: relative;
      top: 18px;
      left: 70px;
    }
    .addanother{
      width: 289.04px;
      height: 58px;
      border-radius: 4px;
      border: 3px solid #052A75;
      color: #052A75;
      position: relative;
      left: 10px;
      top: 40px;
      cursor: pointer;
    }
    .addanadd{
      font-size: 14px;
      font-weight: 600;
      line-height: 20.58px;
      position: relative;
      top: 15px;
      left: 70px
    }
  }

  .checkout-titleweb_account_detailsEdit {
    padding: 15px 45px 15px 45px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    width: 675px;
    height: 1073px;
    background-color: #FFFFFF;
    border-radius: 2px;
    position: relative;
    top: 10px;
    h3{
      font-size: 26px;
      font-weight: 700;
      line-height: 38.23px;
    }
    .userdeta{
      position: relative;
      left: 0px;
      top: -10px;
    }
    .nameareadetail{
      position: relative;
      left: -15px;
    }

    h5{
      font-size: 14px;
      font-weight: 400;
      line-height: 20.58px;
      margin-top: 20px;
      color: #808080;
    }
    h4{
      font-size: 16px;
      font-weight: 500;
      // width: 282px;
    }
    .townarea{
      position: relative;
      left: -15px;
    }
    .townarea2{
      position: relative;
      left: 3px;
    }
    .editbtnaddress{
      width: 289.04px;
      height: 58px;
      border-radius: 4px;
      background-color: #052A75;
      color: #FFFFFF;
      position: relative;
      left: 0px;
      top: 40px;
      cursor: pointer;
    }
    .adredt{
      font-size: 14px;
      font-weight: 600;
      line-height: 20.58px;
      position: relative;
      top: 18px;
      left: 70px;
    }
    .addanother{
      width: 289.04px;
      height: 58px;
      border-radius: 4px;
      border: 3px solid #052A75;
      color: #052A75;
      position: relative;
      left: 10px;
      top: 40px;
      cursor: pointer;
    }
    .addanadd{
      font-size: 14px;
      font-weight: 600;
      line-height: 20.58px;
      position: relative;
      top: 15px;
      left: 70px
    }
    .edtaddArea{
      position: relative;
      top: 100px;
      left: 0px;
      .form-controledtadd {
        border-radius: 5px;
        font-size: 14px;
        width: 601px;
        height: 49px;
        position: relative;
        left: 0px;
      }

      .stree{
        font-size: 14px;
        font-weight: 500;
        line-height: 20.58px;
      }
      .placeorders{
        background-color: #052A75;
        position: relative;
        top: 0px;
        left: 0px;
        width: 213px;
        height: 58px;
        color: #FFFFFF;
        border-radius: 4px;
        cursor: pointer;
    
        .ordbtn {
          position: relative;
          top: 15px;
          color: #FFFFFF;
          left: 73px;
          font-size: 16px;
          font-weight: 600;
          line-height: 26.46px;
          cursor: pointer;
        }
      }
     .form-controltown {
        border-radius: 5px;
        font-size: 14px;
        width: 601px;
        height: 49px;
        position: relative;
        left: 0px;
      }
    }
  }

  .checkout-title2 {
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    width: 423px;
    height: 516px;
    background-color: #FFFFFF;
    border-radius: 2px;
    position: relative;
    left: 130px;
    top: 10px;
    border: 2px solid #D8D8D8;

    h3 {
      color: #444;
      font-weight: 700;
    }

    h5 {
      font-size: 22px;
      font-weight: 500;
      line-height: 32.34px;
      position: relative;
      left: 30px;
      top: 25px;
    }

    .booksSection {
      width: 359px;
      height: 127px;
      border: 1px solid rgba(0, 0, 0, 0.1019607843);
      border-radius: 4px;
      position: relative;
      left: 32px;
      top: 20px;
      margin-top: 25px;
    }

    .checkoutImg {
      position: relative;
      top: 35px;
      left: -20px;
      width: 108px;
      height: 58px;
    }

    .courseesname {
      width: 230px;
      height: 52px;
      line-height: 23.52px;
      font-size: 16px;
      font-weight: 500;
      position: relative;
      top: 5px;
      // left: -15px;
    }
    .batches{
      font-size: 12px;
      font-weight: 700;
      line-height: 17.64px;
      color: #003399;
    }
    .att{
      font-size: 12px;
      font-weight: 400;
    }
    .facultiesname{
      position: relative;
      top: -33px;
      left: 2px;
      width: 200px;
    }
    .ulli {
      position: relative;
      left: 2px;
      top: -40px;
      font-size: 12px;
      font-weight: 400;
      line-height: 17.64px;
    }
    .ulnames {
      position: relative;
      left: -23px;
      font-size: 10px;
      font-weight: 400;
      line-height: 14.7px;
      color: #6D6D6D;
    }
    .orgArea{
      position: relative;
      top: -33px;
      left: 3px;
    }
    .original {
      font-size: 12px;
      font-weight: 400;
      line-height: 17.64px;
      color: #6B6B6B;
    }
    .price {
      font-size: 14px;
      font-weight: 500;
      line-height: 20.58px;
      color: #000000;
    }
    .addbutton {
      position: relative;
      left: 117px;
      top: 40px;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 5px;
      border: none;
      background-color: #289028;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 500;
      line-height: 20.58px;
    }
    .removebutton {
      position: relative;
      left: 96px;
      top: 40px;
      padding-top: 2px;
      padding-bottom: 5px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 5px;
      border: none;
      background-color: #EA0000;
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 500;
      line-height: 20.58px;
    }
    .disArea {
      position: relative;
      top: 90px;
      left: 10px;
    }
    .disPrice {
      font-size: 18px;
      font-weight: 500;
      line-height: 26.46px;
    }
    .amountDetails{
      position: relative;
      top: -25px;
      left: 2px;

      h5{
        font-size: 16px;
        font-weight: 400;
        line-height: 23.52px;
        position: relative;
        left: 0;
        margin-bottom: 12px;
      }

      h4{
        font-size: 18px;
        font-weight: 500;
        line-height: 26.46px;
        position: relative;
        left: 90px;
        top: 25px;
        margin-bottom: 12px;
        color: #008BC1;
      }
    }

    .borderBtm{
      border: 1px solid #E0E0E0;
      position: relative;
      // top: 220px;
      top: 170px;

      left: 30px;
      width: 360px;
    }

    .amountDetails2{
      position: relative;
      top: -15px;

      h5{
        font-size: 18px;
        font-weight: 500;
        line-height: 26.46px;
        position: relative;
        left: 0;
        margin-bottom: 12px;
      }
      h4{
        font-size: 18px;
        font-weight: 500;
        line-height: 26.46px;
        position: relative;
        left: 90px;
        top: 25px;
        margin-bottom: 12px;
        color: #008BC1;
      }
    }

    .placeorders{
      background-color: #052A75;
      position: relative;
      top: 230px;
      left: 32px;
      width: 355px;
      height: 58px;
      color: #FFFFFF;
      border-radius: 4px;
      cursor: pointer;

      .ordbtn {
        position: relative;
        top: -190px;
        color: #FFFFFF;
        left: 120px;
        font-size: 18px;
        font-weight: 600;
        line-height: 26.46px;
      }
    }

  }

  .resp {
    .billingblueback{
      background-color: #052A75;
      // width: 316px;
      height: 37px;
      position: relative;
      // left: -19px;
      top: -10px;
      .alreadyreg{
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 500;
        line-height: 20.58px;
        position: relative;
        // left: 35px;
        width: 100%;
        top: 7px;
        cursor: pointer;
        display: block;
        text-align: center !important;
      }
    }
    .bilship{
      background: rgb(255, 255, 255);
      padding: 10px 20px 10px 20px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
      width: 96%;
      height: 831px;
      position: relative;
      left: 7px;
      h3 {
        font-size: 18px;
        font-weight: 600;
        line-height: 26.46px;
        position: relative;
        top: -10px;
      }
      .namefield{
        line-height: 20.58px;
        text-transform: capitalize;
        color: #333333;
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 14px;
        position: relative;
        left: 2px;
        top: 5px;
      }
      .nameinput{
        border-radius: 4px;
        font-size: 14px;
        width: 100%;
        height: 49px;
        position: relative;
        left: 3px;
      }
      .cityinput{
        border-radius: 4px;
        font-size: 14px;
        width: 114%;
        height: 49px;
        position: relative;
        left: 3px;
      }

    }
    .checkout-title1 {
      padding-bottom: 15px;
      margin-bottom: 10px;
      border-bottom: 1px solid #eee;
      width: 317px;
      height: 530px;
      background-color: #FFFFFF;
      border-radius: 2px;
  
      h3 {
        color: #444;
        font-weight: 700;
      }
  
      h5 {
        font-size: 22px;
        font-weight: 500;
        line-height: 32.34px;
        position: relative;
        left: 30px;
        top: 20px;
      }
  
      .booksSection {
        width: 281px;
        height: 110px;
        border: 1px solid rgba(0, 0, 0, 0.1019607843);
        border-radius: 4px;
        position: relative;
        left: 20px;
        top: 10px;
        margin-top: 25px;
        border-radius: 2px;
      }
  
      .checkoutImg {
        position: relative;
        top: -1px;
        left: -30px;
        width: 76px;
        height: 110px 
      }
  
      .courseesname {
        width: 173px;
        height: 36px;
        line-height: 18.07px;
        font-size: 13px;
        font-weight: 400;
        position: relative;
        top: 5px;
      }
      .facultiesname{
        line-height: 16.17px;
        font-size: 11px;
        font-weight: 400;
        position: relative;
        color: #008BC1;
        width: 92.97px;
        height: 16px;
      }
      .ulli {
        position: relative;
        left: -4px;
        top: -2px;
      }
      .ulnames {
        position: relative;
        left: -23px;
        font-size: 10px;
        font-weight: 400;
        line-height: 14.7px;
        color: #6D6D6D;
      }
      .orgArea{
        position: relative;
        top: 7px;
      }
      .original {
        font-size: 14px;
        font-weight: 400;
        line-height: 20.58px;
        color: #616161;
      }
      .price {
        font-size: 15px;
        font-weight: 500;
        line-height: 22.05px;
        color: #000000;
        position: relative;
        top: -15px 
      }
      .detailRemove{
        width: 281px;
        height: 28px;
        background-color: #FBFBFB;
        position: relative;
        left: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1019607843);
        border-left: 1px solid rgba(0, 0, 0, 0.1019607843);
        border-right: 1px solid rgba(0, 0, 0, 0.1019607843);
        border-radius: 2px;

        .eyecon {
          width: 5.32px;
          height: 4.89px;
          color: #E92323;
        }
        .viewdetails{
          position: relative;
          left: 15px;
          top: 1px;
          font-size: 9.75px;
          font-weight: 500;
          line-height: 14.34px;
          color: #E92323;
        }
        .remove{
          position: relative;
          left: 10px;
          top: 1px;
          font-size: 9.75px;
          font-weight: 500;
          line-height: 14.34px;
          color: #E92323;
        }
      }
      .booksarea{
        position: relative;
        top: 35px;

        .selectBooks{
          font-size: 18px;
          font-weight: 500;
          line-height: 26.46px;
          position: relative;
          left: -10px;
        }

        .seeall{
          font-size: 12px;
          font-weight: 400;
          line-height: 17.64px;
          position: relative;
          left: 90px;
          top: 8px;
          color: #A0A0A0;
          cursor: pointer;
        }
      }

      .bookdiv{
        position: relative;
        top: 45px;

        .bookdiv1{
          border: 1px solid rgba(0, 0, 0, 0.1019607843);
          width: 134px;
          height: 231px;
          border-radius: 2.63px;
          position: relative;
          left: -2px;
          margin-left: 9px;

          .toofanimg{
            width: 78px;
            height: 103px;
            border-radius: 2px;
            position: relative;
            top: 10px;
            left: 17px;
          }
          .booknames{
            font-size: 12px;
            font-weight: 400;
            line-height: 16.2px;
            width: 112px;
            height: 30px;
            position: relative;
            top: 7px;
            // text-align: center;
          }
          .byamansir{
            line-height: 16.17px;
            font-size: 11px;
            font-weight: 400;
            position: relative;
            top: 10px;
            left: 0px;
            width: 92.97px;
            height: 16px;
            color: #008BC1;
          }
          .twoprices{

          }
  
          .priceFirst{
            font-size: 14px;
            font-weight: 500;
            line-height: 20.58px;
          } 
  
          .priceCrossed{
            font-size: 8px;
            font-weight: 400;
            line-height: 11.76px;
            position: relative;
            left: 5px;
            color: #747474;
            text-decoration: line-through;
          }
          .addbook {
            width: 106px;
            height: 21px;
            background-color: #289028;
            color: #FFFFFF;
            text-align: center;
            border-radius: 2px;
            padding: 2px 0px 24px 0px;
            position: relative;
            top: 15px;
            cursor: pointer;
          }

        }

        .bookdiv2{
          border: 1px solid rgba(0, 0, 0, 0.1019607843);
          width: 134px;
          height: 231px;
          border-radius: 2.63px;
          position: relative;
          left: 15px;
        }
        
      }

      .addbutton {
        position: relative;
        left: 117px;
        top: 40px;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 5px;
        border: none;
        background-color: #289028;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 500;
        line-height: 20.58px;
      }
      .removebutton {
        position: relative;
        left: 96px;
        top: 40px;
        padding-top: 2px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 5px;
        border: none;
        background-color: #EA0000;
        color: #FFFFFF;
        font-size: 14px;
        font-weight: 500;
        line-height: 20.58px;
      }
      .disArea {
        position: relative;
        top: 132px;
        left: 20px;
      }
      .disPrice {
        font-size: 20px;
        font-weight: 500;
        line-height: 29.4px;
        color: #E92323;
      }
    }
  
    .checkout-titleresp2 {
      padding-bottom: 15px;
      margin-bottom: 10px;
      border-bottom: 1px solid #eee;
      width: 317px;
      height: 510px;
      background-color: #FFFFFF;
      border-radius: 2px;
      position: relative;
      left: 13px;
      top: 10px;
      border-radius: 2px;

      h3{
        font-size: 18px;
        font-weight: 600;
        line-height: 26.46px;
        position: relative;
        left: 20px;
        top: -5px;
      }
  
      .blueback {
        width: 317px;
        height: 8px;
        background-color: #052A75;
      }
      .userdeta{
        position: relative;
        left: 20px;
        top: -10px;
      }

      h5{
        font-size: 14px;
        font-weight: 400;
        line-height: 20.58px;
        margin-top: 20px;
      }
      h4{
        font-size: 16px;
        font-weight: 500;
        width: 282px;
      }
      .townarea{
        position: relative;
        left: -15px;
      }
      .editbtnaddress{
        width: 136.82px;
        height: 46px;
        border-radius: 4px;
        background-color: #052A75;
        color: #FFFFFF;
        position: relative;
        left: 20px;
        top: 15px;
        cursor: pointer;
      }
      .adredt{
        font-size: 14px;
        font-weight: 600;
        line-height: 20.58px;
        position: relative;
        top: 12px;
        left: 8px;
      }
      .addanother{
        width: 136.82px;
        height: 46px;
        border-radius: 4px;
        border: 3px solid #052A75;
        color: #052A75;
        position: relative;
        left: 27px;
        top: 15px;
        cursor: pointer
      }
      .addanadd{
        font-size: 13px;
        font-weight: 600;
        line-height: 20.58px;
        position: relative;
        top: 10px;
        // left: 2px;
      }
    }

    .checkout-titleresp3 {
      padding-bottom: 15px;
      margin-bottom: 10px;
      border-bottom: 1px solid #eee;
      width: 96%;
      height: 410px;
      background-color: #FFFFFF;
      border-radius: 2px;
      position: relative;
      left: 7px;
      top: 10px;
      border-radius: 2px;
  
      .blueback {
        // width: 317px;
        height: 8px;
        background-color: #052A75;
      }
      .cr{
        position: relative;
        top: 30px;
      }
      .boooksname{
        // width: 189.6px;
        // height: 78px;
        font-size: 18px;
        font-weight: 500;
        line-height: 26.46px;
        left: 10px;
      }
      .boooksfacname{
        color: #008BC1;
        font-size: 14px;
        font-weight: 400;
        line-height: 20.58px;
        position: relative;
        top: -6px;
      }
      .rsbook{
        color: #008BC1;
        font-size: 18px;
        font-weight: 500;
        line-height: 26.46px;
        position: relative;
        // top: 52px;
      }
      .cr2{
        position: relative;
        top: 32px;
        left: -4px;
      }
      .discode{
        // width: 172px;
        height: 53px;
        // border: 2px solid #0000001A;
        border-radius: 4px;
        position: relative;
        left: 3px;
      }
      .inputdisc{
        height: 53px;
        border-radius: 4px;
        // width: 172px;
        position: relative;
        left: -2px;
      }
      .applycoupon{
        // width: 80px;
        // height: 53px;
        background-color: #003399;
        color: #FFFFFF;
        border-radius: 4px;
        cursor: pointer;
        position: relative;
        padding: 14px;
        // left: 20px 
      }
      .appbtn{
        position: relative;
        // top: 13px;
        // left: 5px;
        font-size: 16px;
        font-weight: 500;
        line-height: 23.52px;
        padding-top: 10px;
      }
      .amountDetails{
        position: relative;
        top: 42px;
        left: 0px;
  
        h5{
          font-size: 16px;
          font-weight: 400;
          line-height: 23.52px;
          position: relative;
          left: 0;
          margin-bottom: 9px;
        }
  
        h4{
          font-size: 18px;
          font-weight: 500;
          line-height: 26.46px;
          position: relative;
          text-align: right;
          // left: 31px;
          top: 5px;
          margin-bottom: -5px;
          color: #008BC1;
        }
      }
      .borderBtm{
        border: 1px solid #E0E0E0;
        position: relative;
        // top: 320px;
        top: 290px;
        left: 30px;
        width: 266px 
      }

      .borderBtmByBook{
        border: 1px solid #E0E0E0;
        position: relative;
        top: 355px;
        left: 30px;
        width: 266px 
      }
  
      .amountDetails2{
        position: relative;
        top: 90px;
  
        h5{
          font-size: 18px;
          font-weight: 500;
          line-height: 26.46px;
          position: relative;
          left: 0;
          margin-bottom: 12px;
        }
        h4{
          font-size: 18px;
          font-weight: 500;
          line-height: 26.46px;
          position: relative;
          // left: 58px;
          top: 0px;
          margin-bottom: 12px;
          color: #008BC1;
          text-align: right;
        }
      }
  
      .placeorders{
        background-color: #052A75;
        position: relative;
        top: 400px;
        left: 27px;
        width: 265px;
        height: 58px;
        color: #FFFFFF;
        border-radius: 4px;
        cursor: pointer;
  
        .ordbtn {
          position: relative;
          // top: -301px;
          top: -235px;
          
          color: #FFFFFF;
          left: 70px;
          font-size: 18px;
          font-weight: 600;
          line-height: 26.46px;
          height: 0px;
        }

        .ordbtnByBook {
          position: relative;
          top: -287px;
          color: #FFFFFF;
          left: 90px;
          font-size: 18px;
          font-weight: 600;
          line-height: 26.46px;
          height: 0px;
        }
      }
    }

    .checkout-titleresp4 {
      padding-bottom: 15px;
      margin-bottom: 10px;
      border-bottom: 1px solid #eee;
      width: 317px;
      height: 1145px;
      background-color: #FFFFFF;
      border-radius: 2px;
      position: relative;
      left: 7px;
      top: 10px;
      border-radius: 2px;

      h3{
        font-size: 18px;
        font-weight: 600;
        line-height: 26.46px;
        position: relative;
        left: 20px;
        top: -5px;
      }
  
      .blueback {
        width: 317px;
        height: 8px;
        background-color: #052A75;
      }
      .userdeta{
        position: relative;
        left: 20px;
        top: -10px;
      }

      h5{
        font-size: 14px;
        font-weight: 400;
        line-height: 20.58px;
        margin-top: 20px;
      }
      h4{
        font-size: 16px;
        font-weight: 500;
        width: 282px;
      }
      .townarea{
        position: relative;
        left: -15px;
      }
      .editbtnaddress{
        width: 136.82px;
        height: 46px;
        border-radius: 4px;
        background-color: #052A75;
        color: #FFFFFF;
        position: relative;
        left: 20px;
        top: 15px;
        cursor: pointer;
      }
      .adredt{
        font-size: 14px;
        font-weight: 600;
        line-height: 20.58px;
        position: relative;
        top: 12px;
        left: 8px;
      }
      .addanother{
        width: 136.82px;
        height: 46px;
        border-radius: 4px;
        border: 3px solid #052A75;
        color: #052A75;
        position: relative;
        left: 27px;
        top: 15px;
        cursor: pointer
      }
      .addanadd{
        font-size: 14px;
        font-weight: 600;
        line-height: 20.58px;
        position: relative;
        top: 10px;
        left: 2px;
      }

      .edtaddArea{
        position: relative;
        top: 60px;
        left: 20px;
        width: 282px;

        .stree{
          font-size: 14px;
          font-weight: 500;
          line-height: 20.58px;
        }
        .placeorders{
          background-color: #052A75;
          position: relative;
          top: 0px;
          left: 0px;
          width: 282px;
          height: 58px;
          color: #FFFFFF;
          border-radius: 4px;
          cursor: pointer;
      
          .ordbtn {
            position: relative;
            top: 15px;
            color: #FFFFFF;
            left: 100px;
            font-size: 16px;
            font-weight: 600;
            line-height: 26.46px;
            cursor: pointer;
          }
        }
      }
    }

    .edtaddAreaResp{
      position: relative;
      top: 55px;
      left: 0px;
      .form-controledtadd {
        border-radius: 5px;
        font-size: 14px;
        width: 282px;
        height: 49px;
        position: relative;
        left: 20px;
      }

      .stree{
        font-size: 14px;
        font-weight: 500;
        line-height: 20.58px;
        position: relative;
        left: 20px;
      }
      .placeorders{
        background-color: #052A75;
        position: relative;
        top: 0px;
        left: 20px;
        width: 282px;
        height: 58px;
        color: #FFFFFF;
        border-radius: 4px;
        cursor: pointer;
    
        .ordbtn {
          position: relative;
          top: 15px;
          color: #FFFFFF;
          left: 108px;
          font-size: 16px;
          font-weight: 600;
          line-height: 26.46px;
          cursor: pointer;
        }
      }
     .form-controltown {
        border-radius: 5px;
        font-size: 14px;
        width: 601px;
        height: 49px;
        position: relative;
        left: 0px;
      }
    }
  }
  .placeorders2{
    background-color: #052A75;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 10px;
    text-align: center;
    // height: 46px;
    color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;

    .ordbtn2 {
      position: relative;
      top: 15px;
      color: #FFFFFF;
      left: 25px;
      font-size: 16px;
      font-weight: 600;
      line-height: 26.46px;
      cursor: pointer;
    }
  }

  .checkout-form {
    .checkout-details {
      position: relative;
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      padding: 40px;
    }

    // .check-out {
    //   // .form-group {
    //   //   &:last-child {
    //   //     // margin-bottom: -5px;
    //   //     // label {
    //   //     //   // margin-bottom: -5px;
    //   //     // }
    //   //   }
    //   // }
    // }
    .form-group {
      position: relative;
      margin-bottom: 20px;

      h3 {
        color: #444;
        font-weight: 700;
        margin-bottom: 30px;
        margin-top: 30px;
      }

      .field-label {
        line-height: 23.52px;
        text-transform: capitalize;
        color: #333333;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 16px;
        position: relative;
        left: 35px;
        
        span {
          font-size: 16px;
          // color: $dark-font;
          font-weight: 600;
        }
      }
      .form-control{
        border-radius: 5px;
        font-size: 14px;
        width: 601px;
        height: 49px;
        position: relative;
        left: 35px;
      }

      .form-control2 {
        border-radius: 5px;
        font-size: 14px;
        width: 191.11px;
        height: 49px;
        position: relative;
        left: 35px;
    }

      // label {
      //   // color: $grey;
      // }
    }

    select {
      cursor: pointer;
      -webkit-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      -moz-appearance: none;
      // background: url("#{$assetspath}/dropdown.png") no-repeat 95%;
    }

    input {
      &[type="text"],
      &[type="email"],
      &[type="password"],
      &[type="tel"],
      &[type="number"],
      &[type="url"] {
        width: 100%;
        padding: 0 22px;
        // height: 45px;
        border: 1px solid #dddddd;
      }
    }

    select,
    textarea {
      width: 100%;
      padding: 0 22px;
      height: 45px;
      border: 1px solid #dddddd;
    }
  }

  .check-box {
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
    padding-top: 5px;

    label {
      position: relative;
      top: -1px;
      font-weight: normal;
      padding: 0;
      font-size: 16px;
      cursor: pointer;
      // color: $font-color;
    }
  }

  .lower-content {
    margin-top: 30px;

    .order-column {
      margin-bottom: 40px;
    }
  }
}

.order-box {
  position: relative;
  margin-bottom: 20px;

  .title-box {
    position: relative;
    padding-bottom: 25px;
    color: #444;
    font-weight: 600;
    font-size: 22px;

    // border-bottom: 1px solid #ededed;
    // margin-bottom: 20px;
    span {
      position: relative;
      width: 35%;
      // float: right;
      line-height: 1.2em;
    }
  }

  .qty {
    position: relative;
    border-bottom: 1px solid #ededed;
    margin-bottom: 30px;

    li {
      position: relative;
      display: block;
      font-size: 15px;
      color: #444;
      line-height: 20px;
      margin-bottom: 20px;

      span {
        // float: right;
        font-size: 18px;
        line-height: 20px;
        color: #232323;
        font-weight: 400;
        width: 35%;
      }
    }
  }

  .sub-total {
    position: relative;
    // border-bottom: 1px solid $round-border;
    margin-bottom: 10px;

    li {
      position: relative;
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
      color: #333;
      line-height: 20px;
      margin-bottom: 20px;
      width: 100%;

      .count {
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: #00abed;
        font-weight: 400;
        padding-left: 20px;
        // width: 35%;
        // float: right;
      }
    }

    .shopping-option {
      label {
        position: relative;
        font-size: 16px;
        line-height: 32px;
        padding-left: 10px;
        // color: $grey6;
      }
    }

    .shipping {
      width: 35%;
      float: right;
    }
  }

  .total {
    position: relative;
    margin-bottom: 40px;
    padding-left: 0px;

    li {
      position: relative;
      display: block;
      font-weight: 400;
      // color: $font-color;
      line-height: 20px;
      margin-bottom: 10px;
      font-size: 18px;

      .count {
        position: relative;
        font-size: 18px;
        line-height: 20px;
        color: #00abed;
        font-weight: 400;
      }

      span {
        // float: right;
        font-size: 15px;
        line-height: 20px;
        color: #444;
        font-weight: 400;
        width: 35%;
        display: block;
      }
    }
  }
}

.payment-box {
  position: relative;

  .upper-box {
    position: relative;
  }

  .payment-options {
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px;

    li {
      display: flex;
      margin-bottom: 15px;

      .radio-option {
        position: relative;

        label {
          position: relative;
          padding-left: 30px;
          text-transform: capitalize;
          // color: $grey6;
          cursor: pointer;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 0;
        }

        input[type="radio"] {
          position: absolute;
          left: 0;
          top: 5px;
        }

        label {
          .small-text {
            position: relative;
            display: none;
            font-size: 15px;
            line-height: 25px;
            font-weight: 300;
            color: #666666;
            margin-top: 10px;
          }

          img {
            position: relative;
            display: block;
            max-width: 100%;
            margin-left: -30px;
            margin-top: 5px;
          }
        }
      }
    }
  }
}

.stripe-section {
  // text-transform: uppercase;
  // background-color: #f9f9f9;
  padding: 15px;
  // border: 1px solid #dddddd;
  margin-bottom: 10px;

  table {
    tbody {
      tr {
        td {
          &:first-child {
            padding-right: 15px;
          }
        }
      }
    }
  }

  h5 {
    font-weight: 700;
  }

  .content {
    h5 {
      color: #4f4f4f;
      font-weight: 400;
    }

    table {
      width: 100%;

      tr {
        border-bottom: 1px solid #f1f1f1;

        td {
          padding: 8px 0;
          color: #9a9a9a;

          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
}

.stripe-section {
  .content {
    table {
      tr {
        td {
          &:first-child {
            text-align: right;
          }
        }
      }
    }
  }
}

.logintoacc{
  position: relative;
  left: 20px;
  top: -10px;
  font-size: 26px;
  font-weight: 700;
  line-height: 38.23px;
}
.logintoaccResp{
  position: relative;
  left: 20px;
  top: -10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 26.46px;
}
.h3Login{
  font-size: 14px;
  font-weight: 400;
  margin-left: 23px;
  line-height: 20.58px;
}
.phnmbr{
  font-size: 16px;
  font-weight: 600;
  line-height: 23.52px;
  position: relative;
  left: 7px;
}
.phnmbrResp{
  font-size: 14px;
  font-weight: 500;
  line-height: 23.52px;
  position: relative;
  left: 7px;
}
.nameinputTag{
  width: 601px;
  height: 49px;
  border-radius: 4px;
  position: relative;
  left: 7px;
}
.nameinputTagResp{
  width: 282px;
  height: 49px;
  border-radius: 4px;
  position: relative;
  left: 7px;
}
.lgnbtn{
  width: 174px;
  height: 58px;
  border-radius: 4px;
  position: relative;
  left: 5px;
}
.lgnbtnResp{
  width: 282px;
  height: 58px;
  border-radius: 4px;
  position: relative;
  left: 5px;
}
.placeErrorMessage{
  position: relative;
  left: 35px;
  top: 83px;
  font-size: 15px;
  color: red;
  font-weight: 500;
}
.placeErrorMessageResp{
  position: relative;
  left: 35px;
  top: 190px;
  font-size: 15px;
  color: red;
  font-weight: 500;
}
@media only screen and (min-width: 360px) and (max-width: 768px) {
  .order-box .sub-total {
    position: relative;
    margin-bottom: 10px;
    padding-left: 10px;
  }

  .checkout-page .checkout-form .checkout-details {
    position: relative;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px;
  }

  .order-box .total {
    position: relative;
    margin-bottom: 40px;
    padding-left: 10px;
  }

  .btn {
    line-height: 20px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    border-radius: 0;
  }

  .btn-solid {
    padding: 7px 9px;
    color: #fff !important;
    letter-spacing: 0.05em;
    border: 2px solid #3a519d;
    background-image: linear-gradient(30deg, #3a519d 50%, transparent 0);
    background-size: 540px;
    background-repeat: no-repeat;
    background-position: 0;
  }

  .order-box .total li span {
    font-size: 15px;
    line-height: 20px;
    color: #444;
    font-weight: 400;
    width: 100%;
    display: block;
    margin-left: 20px;
  }
}
@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .order-box .total li span {
    font-size: 15px;
    line-height: 20px;
    color: #444;
    font-weight: 400;
    width: 67%;
    display: block;
    margin-left: 24px;
  }
  .checkout-page .checkout-form .checkout-details {
    margin-top: 10px;
  }
}
@media only screen and (width: 768px) {
  .order-box .total li span {
    margin-left: 20px;
    width: 65%;
  }
  .checkout-page .checkout-form .checkout-details {
    margin-top: 10px;
  }
}
